import React, {Suspense, useContext} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import * as router from 'react-router-dom'
import {Container} from 'reactstrap'
import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppBreadcrumb2 as AppBreadcrumb,
  AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react'

import {routes, navigation, AUTH_ROUTES} from '../../routes'
import {Loading} from '../common/complib'
//import useLocalStorage from '../common/useLocalStorage'
import {FbContext} from '../db'
import Aside from './Aside'
import Footer from './Footer'
import Header from './Header'
const Page404 = React.lazy(() => import('./Page404'))

const Layout = props => {
  const db = useContext(FbContext)
  const user = db.auth.currentUser
  //const [user] = useLocalStorage('loggedUser')

  //console.log('Layout user', user)

  if (!user) {
    props.history.replace(AUTH_ROUTES.SIGN_IN)
    //return null
  }

  const loading = () => <Loading />
  const signOut = event => {
    event.preventDefault()
    props.history.replace(AUTH_ROUTES.SIGN_OUT)
    return null
  }

  return (
    <div className="app">
      <AppHeader fixed>
        <Suspense fallback={loading()}>
          <Header user={user} onLogout={signOut} />
        </Suspense>
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <Suspense>
            <AppSidebarNav navConfig={navigation} {...props} router={router} />
          </Suspense>
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes} router={router} />
          <Container fluid>
            <Suspense fallback={loading()}>
              <Switch>
                {routes.map(route => {
                  const args = route.args ?? ''
                  return route.component ? (
                    <Route
                      key={route.id}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component args={args} {...props} />}
                    />
                  ) : null
                })}
                <Route component={Page404} />
              </Switch>
            </Suspense>
          </Container>
        </main>
        <AppAside fixed>
          <Suspense fallback={loading()}>
            <Aside />
          </Suspense>
        </AppAside>
      </div>
      <AppFooter>
        <Suspense fallback={loading()}>
          <Footer />
        </Suspense>
      </AppFooter>
    </div>
  )
}

export default withRouter(Layout)
