import {useEffect, useState, useContext, useRef} from 'react'

import {FbContext} from '.'
import {buildSearchCriteria, validCompCriteria, buildPersonObject} from '../common/complib'
import {NIMPORTE, SRC_VIEW} from '../db/constants'

export const usePersonCustEmp = (type, searches) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState(null)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      console.log('--- usePersonCustEmp fetchData() ---', type)

      try {
        let result = []
        let srcList
        const {name: searchName, city, comp} = searches

        let dataFromLocalStorage = null
        //const storageKey = `${type}-all`

        // Build specific query if possible
        if (searchName) {
          console.log('FETCH Name =>', type, searchName)
          const [val1, val2] = buildSearchCriteria(searchName)
          srcList = await db.personListCrit(type, 'srchname', val1, val2).get()
        } else if (city) {
          console.log('FETCH City =>', type, city)
          const [val1, val2] = buildSearchCriteria(city)
          srcList = await db.personListCrit(type, 'srchcity', val1, val2).get()
        } else {
          if (comp === NIMPORTE) {
            // Only if TOUS selected
            //dataFromLocalStorage = window.localStorage.getItem(storageKey)
          }
          if (dataFromLocalStorage) {
            console.log('localStorage TRUE for', type)
          } else {
            console.log('No localStorage - FETCH ALL =>', type)
            srcList = await db.personList(type).get()
          }
        }
        if (!dataFromLocalStorage) {
          for (const doc of srcList.docs) {
            if (!validCompCriteria(comp, doc.data().comp)) {
              continue
            }
            const pObj = buildPersonObject(doc)
            result.push(pObj)
          }
          // set to localstorage only if search for ALL
          if (!searchName && !city && comp === NIMPORTE) {
            //window.localStorage.setItem(storageKey, JSON.stringify(result))
          }
        } else {
          result = JSON.parse(dataFromLocalStorage)
        }
        if (isMountedRef.current) {
          setList(result)
          setLoading(false)
        }
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (type && db) {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, searches, type])

  return {error, loading, list}
}

//--------------------------------------------------------------------------------

export const usePerson = (id, type) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [person, setPerson] = useState(null)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pers = await db.getPerson(type, id)
        if (isMountedRef.current) {
          if (pers.data) {
            setPerson(pers.data)
          } else {
            setError(pers.err)
          }
          setLoading(false)
        }
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (id && type) {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, id, type])

  return {error, loading, person}
}

//--------------------------------------------------------------------------------

export const usePersonList = (type, search, fromPlanning = false) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState(null)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const specialSearch = search ? search.includes('/') : false
      try {
        const research = specialSearch ? search.split('/') : []
        let srcList
        if (search !== '//') {
          console.log('--- usePersonList fetchData() ---', type, search)
          srcList = await db.personList(type).get()
        } else {
          srcList = await db.emptyList().get()
        }
        let result = []

        for (const doc of srcList.docs) {
          const name = `${doc
            .data()
            .lastname.toLowerCase()} ${doc.data().firstname.toLowerCase()}`

          if (doc.data().coord) {
            if (specialSearch) {
              let found = false
              for (let index = 0; index < research.length; index++) {
                if (research[index]) {
                  if (name.includes(research[index])) {
                    found = true
                  }
                }
              }
              if (found) {
                result.push({
                  ...doc.data(),
                  title: `${doc.data().lastname} ${doc.data().firstname}`,
                  uid: doc.id,
                })
              }
            } else {
              if (
                type === 'cust' ||
                !fromPlanning ||
                !search ||
                (search && name.includes(search))
              ) {
                result.push({
                  ...doc.data(),
                  title: `${doc.data().lastname} ${doc.data().firstname}`,
                  uid: doc.id,
                })
              }
            }
          } else {
            //console.log(`ERROR: No GPS for ${name}`)
          }
        }
        if (isMountedRef.current) {
          setList(result)
          setLoading(false)
        }
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (type && db) {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, fromPlanning, search, type])

  return {error, loading, list}
}

//--------------------------------------------------------------------------------

export const usePersonSectorList = (type, search, srcView, comp, depl) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState(null)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const getResult = (srcList, type, depl, comp) => {
      let result = []
      for (const doc of srcList.docs) {
        const data = doc.data()
        if (type === 'emp') {
          if (depl !== 'TOUS' && depl !== data.transport) {
            continue
          }
        }
        if (comp !== 'TOUS' && comp !== data.comp) {
          continue
        }
        if (data.coord) {
          result.push({
            ...data,
            title: `${doc.data().lastname} ${doc.data().firstname}`,
            uid: doc.id,
          })
        } else {
          //console.log(`ERROR: No GPS for ${name}`)
        }
      }
      return result
    }

    const manySearch = search ? search.includes('/') : false
    const arraySearch = search.split('/')
    const fetchData = async () => {
      console.log(
        `--- usePersonSectorList fetchData() ${type} --- search:`,
        arraySearch,
        comp,
        depl
      )
      try {
        let srcList
        let result = []
        if (search !== '//') {
          if (manySearch) {
            for (let index = 0; index < arraySearch.length; index++) {
              if (arraySearch[index]) {
                const [val1, val2] = buildSearchCriteria(arraySearch[index])
                const partialList = await db.personListCrit(type, 'srchname', val1, val2).get()
                const partialResult = getResult(partialList, type, depl, comp)
                result = [...result, ...partialResult]
              }
            }
          } else if (search) {
            const [val1, val2] = buildSearchCriteria(search)
            srcList = await db.personListCrit(type, 'srchname', val1, val2).get()
            result = getResult(srcList, type, depl, comp)
          } else {
            srcList = await db.personList(type).get()
            result = getResult(srcList, type, depl, comp)
          }
        } else {
          srcList = await db.emptyList().get()
        }
        if (isMountedRef.current) {
          setList(result)
          setLoading(false)
        }
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (
      type &&
      db &&
      ((type === 'emp' && srcView !== SRC_VIEW.CUST) ||
        (type === 'cust' && srcView !== SRC_VIEW.EMP))
    ) {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, search, type, comp, depl, srcView])

  return {error, loading, list}
}
