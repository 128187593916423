/* eslint-disable no-unused-vars */

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import is from 'is_js'

import {currentConfig} from '../common/complib'

const propTypes = {
  children: PropTypes.node,
}
const defaultProps = {}

export default class Footer extends Component {
  render() {
    // es lint-disable-next-line
    const {children, ...attributes} = this.props
    const config = currentConfig()
    const displayUserAgent = config.includes('N/A')
    const styleText1 = {marginLeft: -10, fontSize: 11, color: '#999'}
    const styleText2 = {fontSize: 11, color: '#999'}
    return (
      <>
        <span title={navigator.userAgent} style={styleText1}>
          {process.env.REACT_APP_LOCAL_APP
            ? process.env.REACT_APP_FIREBASE_DOMAIN
            : process.env.REACT_APP_APPVERSION}
          {' - '}
          {currentConfig()}
        </span>
        <span style={styleText2} className="ml-auto">
          {/* <a
            href="http://faol.site44.com/b4/n.html"
            target="_blank"
            rel="noreferrer noopener"
          > */}
          {/*</a>*/}
          &copy; NETILIA 2021
        </span>
        {displayUserAgent && (
          <>
            <div style={styleText1}>userAgent: {navigator.userAgent}</div>
            <div style={styleText2}> - vendor: {navigator.vendor}</div>
          </>
        )}
      </>
    )
  }
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps
