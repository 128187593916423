import React from 'react'
import {Link} from 'react-router-dom'

import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from 'reactstrap'
import {AppAsideToggler, AppNavbarBrand, AppSidebarToggler} from '@coreui/react'

import logo from '../../../assets/img/netilia/netilia_logo_blanc.png'
import logo_mobile from '../../../assets/img/netilia/netilia_icon120_clear.png'
import {ICON_ROOT} from '../db/constants'

export default ({user, onLogout}) => {
  let username = ''
  let hasPhoto = false
  let avatar = `${ICON_ROOT}/pict_default.png`

  const styleNav = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const styleMenu = {
    right: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'translate3d(-147px, 40px, 0px)',
  }
  const styleNavFull = {src: logo, width: 89, height: 25, alt: 'Logo'}
  const styleNavMinimized = {src: logo_mobile, width: 30, height: 30, alt: 'Logo_mobile'}
  const styleUsername = {fontSize: '10px', marginTop: '-2px'}

  if (user) {
    username = user.displayName ? user.displayName : ''
    let name = username.toLowerCase()
    if (name === 'celia' || name === 'célia') {
      avatar = `${ICON_ROOT}/pict_cb.png`
      hasPhoto = true
    }
    if (name === 'fa' || name === 'francois') {
      avatar = `${ICON_ROOT}/pict_fa.png`
      hasPhoto = true
    }
  }

  return (
    <>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <AppNavbarBrand full={styleNavFull} minimized={styleNavMinimized} />
      <>
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Nav className="d-md-down-none" navbar>
          <NavItem className="px-3">
            <span className="welcome-header">
              Bienvenue <span className="welcome-header-user">{username}</span>
            </span>
          </NavItem>
        </Nav>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown direction="down">
            <DropdownToggle nav>
              <div style={styleNav}>
                <img
                  src={avatar}
                  className="img-avatar img-header"
                  width="40"
                  height="40"
                  title={username}
                  alt="avatar"
                />
                {!hasPhoto && <div style={styleUsername}>{username}</div>}
              </div>
            </DropdownToggle>
            <DropdownMenu right style={styleMenu}>
              <DropdownItem header tag="div" className="text-center">
                <strong>{username}</strong>
              </DropdownItem>
              {username === 'FA' && (
                <>
                  <DropdownItem>
                    <i className="fa fa-wrench" />
                    <Link to="/_maint/fb-func-test" className="nav-link">
                      Firebase Functions
                    </Link>
                  </DropdownItem>
                  {/* <DropdownItem>
                    <i className="fa fa-wrench" />
                    <Link to="/_maint/update-search" className="nav-link">
                      Create Search-Field
                    </Link>
                  </DropdownItem> */}
                </>
              )}
              {process.env.REACT_APP_LOCAL_APP && (
                <>
                  <DropdownItem header tag="div" className="text-center">
                    <strong>{process.env.REACT_APP_FIREBASE_DOMAIN}</strong>
                  </DropdownItem>
                </>
              )}

              <DropdownItem onClick={onLogout}>
                <span className="nav-link">
                  <i className="fa fa-lock" /> Déconnexion
                </span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <AppAsideToggler className="d-md-down-none" /> {/*className="d-lg-none" mobile />*/}
      </>
    </>
  )
}
