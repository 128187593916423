/* eslint-disable no-self-compare */
import React, {useState, useEffect} from 'react'
import is from 'is_js'
import isEqual from 'lodash.isequal'
import omit from 'lodash.omit'
import functions from 'lodash.functions'

import {GAPIKEY, ICON_ROOT, TRANSPORT, SRC_VIEW, NIMPORTE, COMPETENCES} from '../db/constants'

//*********************************************************
export const compValuesSmall = [COMPETENCES.AD, COMPETENCES.ADVF, COMPETENCES.AES]
export const cssValuesSmall = ['comp-ad', 'comp-advf', 'comp-aes']
export const srcValues = [SRC_VIEW.BOTH, SRC_VIEW.CUST, SRC_VIEW.EMP]
export const compValues = [COMPETENCES.AD, COMPETENCES.ADVF, COMPETENCES.AES, NIMPORTE]
export const cssValues = ['comp-ad', 'comp-advf', 'comp-aes', 'comp-all']
export const deplValues = [
  TRANSPORT.BUS,
  TRANSPORT.CAR,
  TRANSPORT.BIKE,
  TRANSPORT.INCONNU,
  NIMPORTE,
]
export const deplValuesSmall = [
  TRANSPORT.BUS,
  TRANSPORT.CAR,
  TRANSPORT.BIKE,
  TRANSPORT.INCONNU,
]
export const imgValues = [
  `${ICON_ROOT}/transport/${TRANSPORT.BUS}.png`,
  `${ICON_ROOT}/transport/${TRANSPORT.CAR}.png`,
  `${ICON_ROOT}/transport/${TRANSPORT.BIKE}.png`,
  `${ICON_ROOT}/transport/${TRANSPORT.INCONNU}.png`,
]
//*********************************************************
export const buildInfoForEmp = (pName, pComp, pDepl) => {
  let name = pName
  let comp = 'comp-' + pComp.toLowerCase() + ' comp-smaller'
  let icon = `${ICON_ROOT}/transport/${pDepl}.png`
  return [name, icon, comp]
}
//*********************************************************
export const buildPersonObject = doc => {
  return {
    ...doc.data(),
    sys: !doc.data().coord ? errorMessageGPS : '',
    title: `${doc.data().lastname} ${doc.data().firstname}`,
    uid: doc.id,
  }
}
export const buildSearchField = value => {
  let cleanValue = value.toLowerCase()
  cleanValue = cleanValue.replace(/é/g, 'e')
  cleanValue = cleanValue.replace(/è/g, 'e')
  cleanValue = cleanValue.replace(/ê/g, 'e')
  cleanValue = cleanValue.replace(/à/g, 'a')
  cleanValue = cleanValue.replace(/â/g, 'a')
  cleanValue = cleanValue.replace(/ù/g, 'u')
  cleanValue = cleanValue.replace(/ü/g, 'u')
  cleanValue = cleanValue.replace(/û/g, 'u')
  cleanValue = cleanValue.replace(/ç/g, 'c')
  return cleanValue
}
export const buildSearchCriteria = value => {
  let val1 = value
  let val2 = null
  const idx = val1.length
  val2 = val1.substring(0, idx - 1) + String.fromCharCode(val1.charCodeAt(idx - 1) + 1)
  return [val1, val2]
}
export const validCompCriteria = (crit, value) => {
  if (crit === COMPETENCES.AD && value !== COMPETENCES.AD) {
    return false
  }
  if (crit !== NIMPORTE && value < crit) {
    return false
  }
  return true
}
export const validDeplCriteria = (crit, value) => {
  if (crit !== NIMPORTE && value !== crit) {
    return false
  }
  return true
}
//*********************************************************
export const mapSize = windowObject => {
  const mapWidth = '100%'
  const mapHeight = windowObject.innerWidth < 511 ? '80vh' : '70vh'
  return {mapWidth, mapHeight}
}
export const LATLONG = {lat: 48.832292, lng: 2.6266372}
export const mapDefault = () => {
  return {
    CENTER: LATLONG,
    URLKEYS: {key: GAPIKEY, language: 'fr'},
  }
}
export const mapOptions = map => {
  return {
    disableDefaultUI: false,
    gestureHandling: 'cooperative',
    streetViewControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: map.MapTypeControlStyle.DROPDOWN_MENU,
    },
    navigationControl: true,
    navigationControlOptions: {
      style: map.NavigationControlStyle.SMALL,
    },
    scaleControl: false,
    zoomControl: true,
    zoomControlOptions: {
      position: map.ControlPosition.RIGHT_TOP,
      style: map.ZoomControlStyle.DEFAULT,
    },
    styles: [
      {
        stylers: [{visibility: 'on'}],
      },
    ],
  }
}
export const mapFilterTitle = (person, value) => {
  if (!value) return false
  const searches = value.split('/')
  for (let index = 0; index < searches.length; index++) {
    const srch = searches[index]
    if (person.title.toLowerCase().includes(srch)) {
      return true
    }
  }
  return false
}
export const mapFilterHours = (person, value) => {
  if (!value) return false
  const searches = value.split('/')
  for (let interv of person.hours) {
    for (let index = 0; index < searches.length; index++) {
      const srch = searches[index]
      if (interv.emp.title && interv.emp.title.toLowerCase().includes(srch)) {
        return true
      }
    }
  }
  return false
}
/*
export const mapFilter = (person, value) => {
  if (!value) return false
  // no filter on Cust
  //if (person.title.toLowerCase().includes(value)) return true
  //if (person.phone.includes(value)) return true
  //if (person.portable.includes(value)) return true 
  //
  for (let interv of person.hours) {
    if (interv.emp.title && interv.emp.title.toLowerCase().includes(value)) {
      return true
    }
    // filter only on name 
    //if (
    //    interv.emp.title.toLowerCase().includes(value) ||
    //    interv.emp.phone.toLowerCase().includes(value) ||
    //    interv.emp.portable.toLowerCase().includes(value) ||
    //    interv.keytime.includes(value)
    //  ) {
    //    return true
    //  } 
    //}
  }
  return false
}
*/
//*********************************************************
export const calcStartEnd = (timeStart, timeEnd) => {
  // Planning Dispo
  //
  // 4x24 pour en plus xxh15 xxh45
  const istart = calcIndex(timeStart)
  const iend = calcIndex(timeEnd)
  return {istart, iend}
}
const calcIndex = value => {
  const time = value.split(':')
  const hour = time[0]
  const min = time[1]
  let idx = parseInt(hour) * 4
  if (min === '15') {
    idx += 1
  } else if (min === '30') {
    idx += 2
  } else if (min === '45') {
    idx += 3
  }
  return idx
}
//*********************************************************
export const Loading = () => (
  <div className="animated fadeIn pt-1 text-center">Chargement...</div>
)
//*********************************************************
export const strlen = (value, len = 0) => {
  if (!isNaN(value)) {
    let str = value.toString()
    while (len > str.length && str.length < 20) {
      str = '0' + str
    }
    return str
  } else {
    return value
  }
}
//*********************************************************
export const shouldUpdate = (prevProps, nextProps) => {
  if (prevProps.options) {
    delete prevProps.options.mapTypeId
  }
  const [prevFuncs, nextFuncs] = [functions(prevProps), functions(nextProps)]
  const value =
    isEqual(omit(prevProps, prevFuncs), omit(nextProps, nextFuncs)) &&
    prevFuncs.every(fn => prevProps[fn].toString() === nextProps[fn].toString())
  //console.log(`== shouldUpdate `, value)
  return value
}

//*********************************************************
export const currentBrowser = () => {
  let browser = 'N/A'
  if (is.chrome()) {
    browser = 'Chrome'
  } else if (is.safari()) {
    browser = 'Safari'
  } else if (is.firefox()) {
    browser = 'Firefox'
  } else if (is.edge()) {
    browser = 'Edge'
  } else if (is.ie()) {
    browser = 'IE'
  }
  return browser
}
export const currentConfig = () => {
  // prettier-ignore
  const os = is.ios()
    ? 'iOS'
    : is.mac()
      ? 'macOS'
      : is.windows()
        ? 'Windows'
        : 'OS N/A'

  // prettier-ignore
  const device = is.desktop()
    ? 'Desktop'
    : is.tablet()
      ? 'Tablet'
      : is.mobile()
        ? 'Mobile'
        : 'Device N/A'

  return `${currentBrowser()} ${device} ${os}`
}
//*********************************************************
export const addDays = (date, days) => {
  const copy = new Date(Number(date))
  copy.setDate(date.getDate() + days)
  return copy
}
export const addMinutes = (date, minutes) => {
  const copy = new Date(Number(date))
  copy.setMinutes(date.getMinutes() + minutes)
  return copy
}
export const addSeconds = (date, seconds) => {
  const copy = new Date(Number(date))
  copy.setSeconds(date.getSeconds() + seconds)
  return copy
}
export const dateDiff = (start, end) => {
  // 24h = 1440min = 86000s = 86000000ms
  const diff = {}
  let tmp = end - start
  // Nombre de secondes entre les 2 dates
  tmp = Math.floor(tmp / 1000)
  diff.sec = tmp % 60
  // Nombre de minutes (partie entière)
  tmp = Math.floor((tmp - diff.sec) / 60)
  diff.min = tmp % 60
  // Nombre d'heures (entières)
  tmp = Math.floor((tmp - diff.min) / 60)
  diff.hour = tmp % 24
  // Nombre de jours
  tmp = Math.floor((tmp - diff.hour) / 24)
  diff.day = tmp
  //
  // To not return 0
  if (Math.round(diff.day + diff.hour + diff.min + diff.sec) === 0) {
    diff.sec = 1
  }
  return diff
}
export const strTimeBetweenDate = (start, end) => {
  let result = ''
  const diff = dateDiff(start, end)
  if (diff.day) {
    result = `${diff.day} j `
  }
  if (diff.hour) {
    result += `${diff.hour} h `
  }
  if (diff.min) {
    result += `${diff.min} min `
  }
  if (diff.sec) {
    result += `${diff.sec} sec`
  }
  return result
}
//*********************************************************
export const buildFrDate = strDate => {
  const {d, m, y, time} = getFrDateElement(strDate)
  return `${m}/${d}/${y} ${time}`
}
export const buildKeyDate = strDate => {
  const {d, m, y, time} = getFrDateElement(strDate)
  return time === ':' ? `${y}-${m}-${d}` : `${y}-${m}-${d}-${time}`
}
export const getFrDateElement = strDate => {
  const d = strDate.substring(0, 2)
  const m = strDate.substring(3, 5)
  const y = strDate.substring(6, 10)
  let t = strDate.substring(11)
  const sep = t.indexOf(':')
  let hour = t.substr(0, sep)
  if (parseInt(hour) < 10 && hour.length === 1) {
    hour = '0' + hour
  }
  const min = t.substr(sep + 1)
  const time = hour + ':' + min
  return {d, m, y, time}
}
export const createDate = date => {
  return new Date(date.replace(/-/g, '/').replace('T', ' '))
}
export const critTime = () => {
  //console.log('critTime =>', new Date().toLocaleTimeString('fr-FR').substring(0, 2) + ':00')
  return new Date().toLocaleTimeString('fr-FR').substring(0, 2) + ':00'
}
export const critHourMax = () => {
  return process.env.REACT_APP_HOUR_MAX
}
export const critDate = () => {
  const now = new Date().toLocaleDateString('fr-FR')
  const nowTime = critTime()
  const nowDate = buildKeyDate(now + ' ' + nowTime).substring(0, 10)
  //console.log('critDate =>', nowTime, nowDate)
  //if (process.env.REACT_APP_LOCAL_APP) {
  //  return [process.env.REACT_APP_HOUR_TEST, process.env.REACT_APP_DATE_TEST]
  //} else {
  return [nowTime, nowDate]
  //}
}
//*********************************************************
export const panelError = (isError, error, bgCss = 'error-panel') => {
  const messageError = typeof error === 'object' ? error.toString() : error
  const message = isError ? messageTranslate(messageError) : ''
  let css = isError ? bgCss : ''
  css += ' error-panel-text center '
  //if (message) {
  //  console.log('***** panelError *****', message)
  //}
  return <div className={css}>{message}</div>
}
export const inputPanelError = (isError, error, withMargin = false, center = true) => {
  let suffix = center ? '-center' : ''
  let css = withMargin ? `error-input-text${suffix} error-mb` : `error-input-text${suffix}`
  const messageError = typeof error === 'object' ? error.toString() : error
  const message = isError ? messageTranslate(messageError) : ''
  //if (message) {
  //  console.log('***** inputPanelError *****', message)
  //}
  return <div className={css}>{message}</div>
}
export const messageTranslate = originalText => {
  let translatedText = ''
  switch (originalText) {
    case 'auth/email-already-in-use':
      translatedText = 'Identifiants déjà utilisés' //'Email déjà enregistré'
      break
    case 'auth/invalid-email':
      translatedText = 'Email invalide'
      break
    case 'auth/weak-password':
    case 'auth/invalid-password':
      translatedText = 'Le Password doit avoir au moins 6 car.'
      break
    case 'auth/user-disabled':
      translatedText = 'Compte Utilisateur bloqué'
      break
    case 'auth/wrong-password':
    case 'auth/user-not-found':
      translatedText = 'Identifiants inconnus'
      break
    default:
      translatedText =
        originalText !== '' ? originalText : 'Erreur en création - Vérifier les données'
      break
  }
  return translatedText
}
export const errorMessageGPS = 'Erreur: pas de coordonnées GPS'
//
// https://overreacted.io/a-complete-guide-to-useeffect/
//*********************************************************
export const useFetch = (url, options) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [status, setStatus] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const opt = options ? JSON.parse(options) : {}
        const response = await fetch(url, opt)
        if (!response.ok) {
          console.log('*** useFetch response: ' + response.status)
          throw Error(`${response.status} ${response.statusText}`)
        }
        const data = await response.json()
        if (data.error) {
          console.log('*** useFetch data.error: ' + data.error.code)
          throw Error(`${data.error.code} ${data.error.message}`)
        } else {
          setData(data)
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        setStatus(err.message)
        setData(null)
      }
    }
    fetchData()
  }, [url, options])

  return {data, status, loading}
}
//*********************************************************
export const useInputValue = initialValue => {
  const [value, setValue] = React.useState(initialValue)
  return {
    value,
    setValue,
    bind: {
      value,
      onChange: e => setValue(e.target.value),
    },
  }
}
export const useCheckboxValue = initialValue => {
  const [checked, setChecked] = React.useState(initialValue)
  return {
    checked,
    setChecked,
    bind: {
      checked,
      onChange: e => setChecked(e.target.checked),
    },
  }
}
// With Reset()
export const useInputResetValue = initialValue => {
  const [value, setValue] = React.useState(initialValue)
  return {
    value,
    setValue,
    bind: {
      value,
      onChange: e => setValue(e.target.value),
    },
    reset: () => setValue(''),
  }
}
export const useCheckboxResetValue = initialValue => {
  const [checked, setChecked] = React.useState(initialValue)
  return {
    checked,
    setChecked,
    bind: {
      checked,
      onChange: e => setChecked(e.target.checked),
    },
    reset: () => setChecked(false),
  }
}
//*********************************************************

export const buildInfo = (info, type, doublePopup) => {
  const telLink = tel => {
    return `<a class='telephoneLink' href="tel:${tel}">${tel}</a>`
  }
  const name = info.title
  const phone1 = info.phone ? telLink(info.phone) : ''
  const phone2 = info.portable ? telLink(info.portable) : ''
  let html = ''
  let title = ''
  let cssPopup = ''
  let cssComp = 'comp-' + info.comp.toLowerCase()

  title = type === 'cust' ? 'Bénéficiaire' : 'Intervenant'
  cssPopup = type === 'cust' ? 'popup-1' : 'popup-2'

  if (doublePopup) {
    const entry = info.hours[0]
    const emp = entry.emp
    const cssComp2 = 'comp-' + emp.comp.toLowerCase()
    const phone2_1 = emp.phone ? telLink(emp.phone) : ''
    const phone2_2 = emp.portable ? telLink(emp.portable) : ''
    html = `<div class='popup-header-time'>à ${entry.keytime} pour ${entry.hours} h</div>
            <div class='double-popup'>
              <div class='popup ${cssPopup}'>
                <div class='popup-header-title'>${title}</div>
                <h5 class='popup-header'>${name}</h5>
                <div class='popup-info'>
                  <p style='min-height:57px'>
                    ${info.address}
                    <br/>
                    ${info.zip} ${info.city}
                  </p>
                  <p style='min-height:38px'>
                    ${phone1}
                    <br/>
                    ${phone2}
                  </p>
                  <div style='height: 23px;' class='${cssComp}'>${info.comp}</div>
                  <p>${info.obs}</p>
                </div>
              </div>
              <div class='popup popup-2'>
                <div class='popup-header-title'>Intervenant</div>
                <h5 class='popup-header'>${emp.lastname + ' ' + emp.firstname}</h5>
                <div class='popup-info'>
                  <p style='min-height:57px'>
                    ${emp.address}
                    <br/>
                    ${emp.zip} ${emp.city}
                  </p>
                  <p style='min-height:38px'>
                    ${phone2_1}
                    <br/>
                    ${phone2_2 ? phone2_2 : '-'}
                  </p>
                  <div class='popup-emp-icon'>
                    <div style='height: 23px;' class='${cssComp2}'>${emp.comp}</div>
                    <div>
                      <img
                        src=${ICON_ROOT}/transport/${emp.transport}.png
                        title=${emp.transport}
                        alt="transport"
                      />
                    </div>
                  </div>
                  <p>${emp.obs}</p>
                </div>
              </div>
            </div>`
  } else {
    html = `<div class='popup popup-rounded ${cssPopup}'>
              <div class='popup-header-title'>${title}</div>
              <h5 class='popup-header'>${name}</h5>
              <div class='popup-info'>
                <p>
                  ${info.address} - ${info.zip} ${info.city}
                </p>
                <p>
                  ${phone1} - ${phone2}
                </p>
                <p><div style='height: 23px;' class='${cssComp}'>${info.comp}</div></p>
                <p>${info.obs}</p>
              </div>
            </div>`
  }

  return html
}
//*********************************************************
/* export const getBadge = status => {
  switch (status) {
    case 'Active':
      return 'success'
    case 'Inactive':
      return 'warning'
    case 'Pending':
      return 'secondary'
    case 'Alzheimer':
      return 'danger'
    default:
      return 'primary'
  }
} */
//*********************************************************
/* Numeric Format */
// Number.parseFloat(el.price_usd).toFixed(3)
export const billionFormatter = amount => {
  return amount > 999999999
    ? (amount / 1000000000).toFixed(3) + ' B'
    : millionFormatter(amount)
}
const millionFormatter = amount => {
  return amount > 999999 ? (amount / 1000000).toFixed(1) + ' m' : amount
}
//*********************************************************
/*
export const getPersonFieldName = (ix, headerFields) => {
  let name = ''
  if (headerFields.length !== 0) {
    const field = headerFields[ix].toLowerCase()
    switch (field) {
      case 'nom':
        name = 'lastname'
        break
      case 'prénom':
        name = 'firstname'
        break
      case 'adresse':
        name = 'address'
        break
      case 'c.postal':
        name = 'zip'
        break
      case 'ville':
        name = 'city'
        break
      case 'tél.1':
        name = 'phone'
        break
      case 'tél.2':
        name = 'portable'
        break
      case 'compétences':
        name = 'comp'
        break
      case 'observations':
        name = 'obs'
        break
      case 'latitude':
        name = 'lat'
        break
      case 'longitude':
        name = 'lng'
        break
      case 'transport':
        name = 'transport'
        break
      default:
        name = 'INCONNU'
        break
    }
  } else {
    switch (ix) {
      case 0:
        name = 'lastname'
        break
      case 1:
        name = 'firstname'
        break
      case 3:
        name = 'address'
        break
      case 4:
        name = 'zip'
        break
      case 5:
        name = 'city'
        break
      case 6:
        name = 'phone'
        break
      case 7:
        name = 'portable'
        break
      case 8:
        name = 'comp'
        break
      case 9:
        name = 'obs'
        break
      case 10:
        name = 'lat'
        break
      case 11:
        name = 'lng'
        break
      case 12:
        name = 'transport'
        break
      default:
        name = 'INCONNU'
        break
    }
  }
  return name
}

export const getPlanningFieldName = (ix, headerFields) => {
  let name = ''
  if (headerFields.length !== 0) {
    const field = headerFields[ix].toLowerCase()
    switch (field) {
      case 'jour':
        name = 'dayname'
        break
      case 'date de début':
        name = 'datestart'
        break
      case 'date de fin':
        name = 'dateend'
        break
      case 'heures':
        name = 'hours'
        break
      case 'usager':
        name = 'cust'
        break
      case 'salarié':
        name = 'emp'
        break
      default:
        name = 'INCONNU'
        break
    }
  } else {
    switch (ix) {
      case 4:
        name = 'dayname'
        break
      case 5:
        name = 'datestart'
        break
      case 6:
        name = 'dateend'
        break
      case 7:
        name = 'hours'
        break
      case 12:
        name = 'cust'
        break
      case 18:
        name = 'emp'
        break
      default:
        name = 'INCONNU'
        break
    }
  }
  return name
}
*/
