import './wdyr'

import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/ie11' // For IE 11 support
import './app/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import FbInstance, {FbContext} from './app/components/db'
import App from './app/App'
import * as serviceWorker from './app/serviceWorker'

ReactDOM.render(
  <FbContext.Provider value={new FbInstance()}>
    <App />
  </FbContext.Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
