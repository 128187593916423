import React from 'react'

const Dashboard = React.lazy(() => import('./components/Dashboard'))
const ImportFile = React.lazy(() => import('./components/ImportFile'))

const MpSector = React.lazy(() => import('./components/map/Sector'))
const MpPlann = React.lazy(() => import('./components/map/Plann'))

const Person = React.lazy(() => import('./components/Person'))
const PersonForm = React.lazy(() => import('./components/PersonForm'))
const ListCustEmp = React.lazy(() => import('./components/ListCustEmp'))

const Planning = React.lazy(() => import('./components/planning/Hour'))
const Dispo = React.lazy(() => import('./components/planning/Dispo'))

const UpdateSearch = React.lazy(() => import('./components/maintenance/UpdateSearchField'))
const FbFuncTest = React.lazy(() => import('./components/maintenance/FbFunc'))

const AUTH_ROUTES = {
  HOME: '/',
  SIGN_IN: '/login',
  SIGN_UP: '/register',
  SIGN_OUT: '/signout',
}

const Home = () => {
  const css = {height: '100vh'}
  return (
    <div className="app-home">
      <div style={css}></div>
    </div>
  )
}

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
//
const routes = [
  {
    id: 100,
    path: '/',
    exact: true,
    name: 'Accueil',
    component: Home,
  },
  {
    id: 110,
    path: '/dashboard',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    id: 120,
    path: '/update',
    exact: true,
    name: 'Update',
    component: PersonForm,
  },
  {
    id: 130,
    path: '/list/cust',
    exact: true,
    name: 'Liste',
    args: 'cust',
    component: ListCustEmp,
  },
  {
    id: 140,
    path: '/list/emp',
    exact: true,
    name: 'Liste Intervenants',
    args: 'emp',
    component: ListCustEmp,
  },
  /****************************************** VUE PLANNING */
  {
    id: 200,
    path: '/dispo',
    exact: true,
    name: 'Disponibilités',
    component: Dispo,
  },
  {
    id: 210,
    path: '/plan/cust',
    exact: true,
    name: 'Vue Planning Bénéficiaires',
    args: 'cust',
    component: Planning,
  },
  {
    id: 220,
    path: '/plan/emp',
    exact: true,
    name: 'Vue Planning Intervenants',
    args: 'emp',
    component: Planning,
  },
  {
    id: 230,
    path: '/plan/hour',
    exact: true,
    name: 'Vue Planning Horaire',
    args: 'hour',
    component: Planning,
  },

  /****************************************** MAPS */
  {
    id: 310,
    path: '/map/planning',
    exact: true,
    name: 'Map Planning',
    component: MpPlann,
  },
  {
    id: 320,
    path: '/map/sector',
    exact: true,
    name: 'Map Sectorisation',
    component: MpSector,
  },
  /****************************************** IMPORT */
  {
    id: 401,
    path: '/csv/planning',
    exact: true,
    name: 'Import Planning',
    args: 'planning',
    component: ImportFile,
  },
  {
    id: 402,
    path: '/csv/cust',
    exact: true,
    name: 'Import Bénéficiaires',
    args: 'cust',
    component: ImportFile,
  },
  {
    id: 403,
    path: '/csv/emp',
    exact: true,
    name: 'Import Intervenants',
    args: 'emp',
    component: ImportFile,
  },
  {
    id: 444,
    path: '/csv/emp-comp',
    exact: true,
    name: 'Import Intervenants',
    args: 'emp-comp',
    component: ImportFile,
  },
  /****************************************** MAINTENANCE*/
  {
    id: 1010,
    path: '/_maint/update-search',
    exact: true,
    name: 'UpdateSearch',
    component: UpdateSearch,
  },
  {
    id: 1011,
    path: '/_maint/fb-func-test',
    exact: true,
    name: 'FBFunc-Test',
    component: FbFuncTest,
  },
  /****************************************** */
  {
    id: 500,
    path: '/:type/:uid',
    exact: true,
    name: 'Fiche',
    component: Person,
  },
]

/*=================================================================== */

const navigation = {
  items: [
    {
      name: 'Tableau de bord',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'Bénéficiaires',
      url: '/list/cust',
      icon: 'icon-people',
    },
    {
      name: 'Intervenants',
      url: '/list/emp',
      icon: 'icon-user',
    },
    {
      //**************************************************  VUE PLANNING
      title: true,
      name: 'Vue Planning',
    },
    {
      name: 'Disponibilités',
      url: '/dispo',
      icon: 'icon-calendar',
    },
    {
      name: 'Bénéficiaires',
      url: '/plan/cust',
      icon: 'icon-list',
    },
    {
      name: 'Intervenants',
      url: '/plan/emp',
      icon: 'icon-equalizer',
    },
    {
      name: 'Horaire',
      url: '/plan/hour',
      icon: 'icon-badge',
    },
    {
      //**************************************************  MAPS
      title: true,
      name: 'Maps',
    },
    {
      name: 'Planning',
      url: '/map/planning',
      icon: 'icon-map',
    },
    {
      name: 'Sectorisation',
      url: '/map/sector',
      icon: 'icon-map',
    },
    {
      //**************************************************  IMPORTATION
      title: true,
      name: 'Importation',
      wrapper: {
        // optional wrapper object
        element: '', // required valid HTML5 element tag
        attributes: {}, // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: '', // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Planning CSV',
      url: '/csv/planning',
      icon: 'icon-cloud-download',
    },
    {
      name: 'Bénéficiaires CSV',
      url: '/csv/cust',
      icon: 'icon-cloud-download',
    },
    {
      name: 'Intervenants CSV',
      url: '/csv/emp',
      icon: 'icon-cloud-download',
    },
    {
      name: `Netilia-Formation`,
      url: 'https://www.netilia-formation.fr',
      icon: 'fa fa-graduation-cap',
      class: 'mt-auto',
      variant: 'success',
      attributes: {target: '_blank', rel: 'noopener noreferrer'},
    },
  ],
}

export {routes, navigation, AUTH_ROUTES}

/* 
if (process.env.REACT_APP_LOCAL_APP) {
  navigation.items.push({
    //============================== TEST
    title: true,
    name: 'LOCAL TESTING',
  })
  navigation.items.push({
    name: 'Bénéficiaires',
    url: '/oldlist/cust',
    icon: 'icon-user',
  })
}
 */
