export const SRC_VIEW = {
  CUST: 'Bénéficiaires',
  EMP: 'Intervenants',
  BOTH: 'Tous',
}
export const MRK_TYPE = {
  ICON: 'Icône',
  TEXT: 'Texte',
}
export const IMPORT_TYPE = {
  CUST: 'Bénéficiaires',
  EMP: 'Intervenants',
  PLA: 'Planning',
}
export const MODAL_TYPE = {
  MAP: 'Carte',
  UPDATE: 'Modif',
}
export const TRANSPORT = {
  CAR: 'voiture',
  BIKE: 'velo',
  BUS: 'transport',
  INCONNU: 'inconnu',
}
export const BOOL_TYPE = {
  TRUE: 'Oui',
  FALSE: 'Non',
}
export const CIVILITE = {
  MME: 'Mme',
  MR: 'Mr',
}
export const COMPETENCES = {
  AD: 'AD',
  ADVF: 'ADVF',
  AES: 'AES',
}
export const NIMPORTE = 'TOUS'
export const GAPIKEY = process.env.REACT_APP_GMAP_KEY
export const ICON_ROOT = process.env.PUBLIC_URL + '/assets/img'
export const IMG_ROOT = `${ICON_ROOT}/mapper/`
export const IMG_CUST = `${IMG_ROOT}person.png`
export const IMG_WIDTH_CUST = 40
export const IMG_HEIGHT_CUST = 40
export const IMG_WIDTH_EMP = 25
export const IMG_HEIGHT_EMP = 25
export const LABEL_ORIGIN_X = 20 // 100
export const LABEL_ORIGIN_Y = 45
