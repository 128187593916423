import {useEffect, useState, useContext, useRef} from 'react'

import {buildSearchCriteria} from '../common/complib'
import {usePersonList} from '.'
import {FbContext} from '.'

export const usePlanMap2 = (
  day,
  hour,
  search,
  searchCust,
  hourMax = process.env.REACT_APP_HOUR_MAX
) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([]) // List by CUST
  const [list2, setList2] = useState([]) // List by EMP
  const [list3, setList3] = useState([]) // CUST à placer sur MAP

  const {list: custList} = usePersonList('cust', search, true)
  const {list: empList} = usePersonList('emp', search, true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = []
        let result2 = []
        let result3 = []

        if (empList && empList.length > 0 && custList && custList.length > 0) {
          console.log('--- usePlanMap2 fetchData() ---', hour, hourMax)

          const hourDocs = await db.planListHourForPlanningMap(day, hour, hourMax).get()
          hourDocs.forEach(async doc => {
            let id = doc.data().keytime
            let time = await db.planListHourEmp(day, id).get()

            //TODO: for(let doc of time.docs.docs)
            time.docs.forEach(doc => {
              const data = doc.data()

              if (data.keyday === day && data.keytime >= hour && data.keytime <= hourMax) {
                const emp = empList.find(p => p.lastname + ' ' + p.firstname === data.emp)
                const cust = custList.find(p => p.lastname + ' ' + p.firstname === data.cust)

                //console.log(data.keytime, data.cust, data.emp)

                //TODO: need some checking...
                //if (!cust && !search) {
                //  console.log(`CUST inconnu - ${data.cust} - ${custList.length}`)
                //}
                //if (!emp && !search) {
                //  console.log(`EMP inconnu - ${data.emp} - ${empList.length}`)
                //}

                if (emp && cust) {
                  // check if emp exists (emp can be null if search was used and 'emp' has been cleared in UsePersonList)
                  const hourEntry = {
                    keyday: data.keyday,
                    keytime: data.keytime,
                    emp: {...emp},
                    cust: {...cust},
                    keytimeend: data.keytimeend,
                    hours: data.hours,
                    pathtime: data.pathtime,
                  }

                  const index = result.findIndex(
                    p => p.title === cust.lastname + ' ' + cust.firstname
                  )
                  if (index === -1) {
                    result.push({
                      title: cust.lastname + ' ' + cust.firstname,
                      ...cust,
                      hours: [hourEntry],
                    })
                  } else {
                    const custResult = result[index]
                    const entries = custResult.hours
                    entries.push(hourEntry)
                    result[index].hours = entries
                  }

                  // List EMP
                  const index2 = result2.findIndex(
                    p => p.title === emp.lastname + ' ' + emp.firstname
                  )
                  if (index2 === -1) {
                    result2.push({
                      title: emp.lastname + ' ' + emp.firstname,
                      ...emp,
                      hours: [hourEntry],
                    })
                  } else {
                    const empResult = result2[index2]
                    const entries = empResult.hours
                    entries.push(hourEntry)
                    result2[index2].hours = entries
                  }
                } else {
                  if (search) {
                    //console.log('** OK with search')
                  } else {
                    //console.log('********** EMP or CUST undefined')
                  }
                }
              }
            })
          })

          // Handling searchCust (Bénéficiaire(s) à placer sur le carte)
          if (searchCust) {
            const [val1, val2] = buildSearchCriteria(searchCust)
            const custToPlace = await db.personListCrit('cust', 'srchname', val1, val2).get()
            for (const doc of custToPlace.docs) {
              const data = doc.data()
              if (data.coord) {
                result3.push({
                  ...data,
                  title: `${doc.data().lastname} ${doc.data().firstname}`,
                  uid: doc.id,
                })
              } else {
                //console.log(`ERROR: No GPS for ${name}`)
              }
            }
          }
        }
        setTimeout(() => {
          if (isMountedRef.current) {
            setList(result)
            setList2(result2)
            setList3(result3)
            setLoading(false)
          }
        }, 1000)
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (search !== '//') {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, search, searchCust, day, hour, hourMax, custList, empList])

  return {error, loading, list, list2, list3}
}

//--------------------------------------------------------------------------------

/*
export const usePlanMap = (day, hour, search, hourMax = process.env.REACT_APP_HOUR_MAX) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([]) // List by CUST
  const [list2, setList2] = useState([]) // List by EMP

  const {list: custList} = usePersonList('cust', search, true)
  const {list: empList} = usePersonList('emp', search, true)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result = []
        let result2 = []

        if (empList && empList.length > 0 && custList && custList.length > 0) {
          //console.log('usePlanMap fetchData()', hour, hourMax)

          const hourDocs = await db.planListHourForPlanningMap(day, hour, hourMax).get()
          hourDocs.forEach(async doc => {
            let id = doc.data().keytime
            let time = await db.planListHourEmp(day, id).get()

            //TODO: for(let doc of time.docs.docs)
            time.docs.forEach(doc => {
              const data = doc.data()

              if (data.keyday === day && data.keytime >= hour && data.keytime <= hourMax) {
                const emp = empList.find(p => p.lastname + ' ' + p.firstname === data.emp)
                const cust = custList.find(p => p.lastname + ' ' + p.firstname === data.cust)

                //console.log(data.keytime, data.cust, data.emp)

                //TODO: need some checking...
                //if (!cust && !search) {
                //  console.log(`CUST inconnu - ${data.cust} - ${custList.length}`)
                //}
                //if (!emp && !search) {
                //  console.log(`EMP inconnu - ${data.emp} - ${empList.length}`)
                //}

                if (emp && cust) {
                  // check if emp exists (emp can be null if search was used and 'emp' has been cleared in UsePersonList)
                  const hourEntry = {
                    keyday: data.keyday,
                    keytime: data.keytime,
                    emp: {...emp},
                    cust: {...cust},
                    keytimeend: data.keytimeend,
                    hours: data.hours,
                    pathtime: data.pathtime,
                  }

                  const index = result.findIndex(
                    p => p.title === cust.lastname + ' ' + cust.firstname
                  )
                  if (index === -1) {
                    result.push({
                      title: cust.lastname + ' ' + cust.firstname,
                      ...cust,
                      hours: [hourEntry],
                    })
                  } else {
                    const custResult = result[index]
                    const entries = custResult.hours
                    entries.push(hourEntry)
                    result[index].hours = entries
                  }

                  // List EMP
                  const index2 = result2.findIndex(
                    p => p.title === emp.lastname + ' ' + emp.firstname
                  )
                  if (index2 === -1) {
                    result2.push({
                      title: emp.lastname + ' ' + emp.firstname,
                      ...emp,
                      hours: [hourEntry],
                    })
                  } else {
                    const empResult = result2[index2]
                    const entries = empResult.hours
                    entries.push(hourEntry)
                    result2[index2].hours = entries
                  }
                } else {
                  if (search) {
                    //console.log('** OK with search')
                  } else {
                    //console.log('********** EMP or CUST undefined')
                  }
                }
              }
            })
          })
        }
        setTimeout(() => {
          if (isMountedRef.current) {
            setList(result)
            setList2(result2)
            setLoading(false)
          }
        }, 1000)
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    if (search !== '//') {
      fetchData()
    } else {
      if (isMountedRef.current) {
        setLoading(false)
      }
    }
  }, [db, search, day, hour, hourMax, custList, empList])

  return {error, loading, list, list2}
}
*/
