import {useEffect, useState, useContext, useRef} from 'react'

import {FbContext} from '.'
import {
  calcStartEnd,
  critDate,
  critTime,
  validCompCriteria,
  validDeplCriteria,
} from '../common/complib'

export const usePlan = (day, type, start, end, comp, depl) => {
  const isMountedRef = useRef(true)
  const db = useContext(FbContext)

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [planList, setPlanList] = useState(null)

  useEffect(() => {
    return () => {
      isMountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      console.log('--- usePlan fetchData() ---', type)

      try {
        let result = []
        let emps = []
        let ref = null
        let empList = null
        const doFilter = comp !== 'TOUS' || depl !== 'TOUS'

        switch (type) {
          case 'cust':
          case 'emp':
            ref = await db.planListPerson(type, day)
            break
          case 'dispo':
            ref = await db.planListPerson('emp', day)
            // Now always to retrieve Comp&Depl - before only if (doFilter)
            empList = await db.personList('emp').get()
            empList.forEach(doc => {
              const empData = doc.data()
              emps.push({
                name: `${empData.lastname} ${empData.firstname}`,
                comp: empData.comp,
                transport: empData.transport,
              })
            })
            break
          default:
            //type: hour
            if (critDate()[1] === day) {
              ref = await db.planListHourFiltered(day, critTime())
            } else {
              ref = await db.planListHour(day)
            }
            break
        }

        const refDocs = await ref.get()
        refDocs.forEach(async doc => {
          let id = null
          let getData = null

          switch (type) {
            case 'cust':
              id = doc.data().cust
              getData = await db.planListPersonHour(type, day, id)
              break
            case 'emp':
            case 'dispo':
              id = doc.data().emp
              if (doFilter) {
                const emp = emps.filter(elt => elt.name === id)[0]
                if (emp) {
                  if (
                    !validDeplCriteria(depl, emp.transport) ||
                    !validCompCriteria(comp, emp.comp)
                  ) {
                    return
                  }
                }
              }
              getData = await db.planListPersonHour('emp', day, id)
              break
            default:
              id = doc.data().keytime
              getData = await db.planListHourEmp(day, id)
              break
          }

          const time = await getData.get()
          //TODO: for(let doc of time.docs.docs)
          time.docs.forEach(doc => {
            const data = doc.data()
            if (type === 'dispo') {
              // Planning Dispo
              //
              const empData = emps.filter(emp => emp.name === data.emp)[0]
              if (empData) {
                data.comp = empData.comp
                data.transport = empData.transport
              }

              const index = result.findIndex(p => p.emp === data.emp)
              let idx

              if (index === -1) {
                // 4x24 pour en plus xxh15 xxh45
                const dispo = Array(96).fill(0)
                result.push({
                  ...data,
                  dispo,
                })
                idx = result.length - 1
              } else {
                idx = index
              }

              const {istart, iend} = calcStartEnd(data.keytime, data.keytimeend)
              const arr = result[idx].dispo
              for (let ix = istart; ix < iend; ix++) {
                arr[ix] = 1
              }
            } else {
              // Other Planning
              //
              result.push({
                ...data,
              })
            }
          })
        })
        setTimeout(() => {
          if (isMountedRef.current) {
            setPlanList(result)
            setLoading(false)
          }
        }, 1000)
      } catch (err) {
        if (isMountedRef.current) {
          setLoading(false)
          setError(err)
        }
      }
    }
    fetchData()
  }, [db, day, type, comp, depl])

  return {error, loading, planList}
}
