import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import {TRANSPORT} from './constants'
import {buildPersonObject, buildSearchField} from '../common/complib'

const LOCAL_APP = process.env.REACT_APP_LOCAL_APP

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_DOMAIN}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_DOMAIN}.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_DOMAIN,
  storageBucket: `${process.env.REACT_APP_FIREBASE_DOMAIN}.appspot.com`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_SENDERID}`,
  appId: `1:${process.env.REACT_APP_FIREBASE_SENDERID}:web:${process.env.REACT_APP_FIREBASE_APPID}`,
}

// https://www.robinwieruch.de/react-firestore-tutorial/
class FbInstance {
  constructor() {
    firebase.initializeApp(config)

    if (LOCAL_APP) {
      firebase.functions().useFunctionsEmulator('http://localhost:5001')
    }

    // The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
    // for a different threshold (minimum 1 MB) or set to "CACHE_SIZE_UNLIMITED" to disable clean-up.
    // example: 25 * 1000000 => 25 Mo
    //
    //firebase.firestore().settings({
    //  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
    //})
    /* firebase
      .firestore()
      .enablePersistence()
      .catch(err => {
        console.log('Fb Error', err)
        if (err.code === 'failed-precondition') {
          // multiple tabs open: persistence only enabled in one tab at a time
        } else if (err.code === 'unimplemented') {
          // no support in current browser
        }
      })
    */

    this.fieldValue = firebase.firestore.FieldValue
    this.firestore = firebase.firestore()
    this.functions = firebase.functions()
    this.auth = firebase.auth()
  }

  // Test 'callable' firebase function
  doFireUsers = () => {
    return this.functions
      .httpsCallable('fireUsers')()
      .then(res => res)
      .catch(err => console.log('error', err))
  }

  //-----------------------------------------------------------
  //
  isInitialized = () => {
    return new Promise(resolve => {
      this.auth.onAuthStateChanged(resolve)
    })
  }

  // Auth API
  doCreateUser = async (name, email, password) => {
    const authUser = await this.auth.createUserWithEmailAndPassword(email, password)
    await this.auth.currentUser.updateProfile({displayName: name})
    return authUser
  }

  // Persistence until expressively logout (persistence even if browser closed)
  doSignIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password)
  //
  // https://firebase.google.com/docs/auth/web/auth-state-persistence
  // Persistence SESSION
  /*doSignIn = (email, password) => {
    return this.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION).then(() => {
      return this.auth.signInWithEmailAndPassword(email, password)
    })
  }*/

  doSignOut = () => {
    //console.log('doSignOut')
    //window.localStorage.removeItem('loggedUser')
    return this.auth.signOut()
  }

  // User API
  //
  user = uid => this.firestore.doc(`users/${uid}`)
  users = () => this.firestore.collection('users')

  emptyList = () => this.firestore.collection('empty')

  person = (table, uid) => this.firestore.doc(`${table}/${uid}`)
  personList = table => this.firestore.collection(table)
  personListCrit = (table, field, val1, val2) => {
    if (val2) {
      return this.firestore.collection(table).where(field, '>=', val1).where(field, '<', val2)
    } else {
      return this.firestore
        .collection(table)
        .where(field, 'in', [val1, val1.toLowerCase(), val1.toUpperCase()])
    }
  }

  getPerson = async (table, uid) => {
    const doc = await this.person(table, uid).get()
    if (!doc.exists) {
      return {data: null, err: `ERREUR: ${table} ${uid} Fiche non trouvée!`}
    } else {
      const data = buildPersonObject(doc)
      return {data, err: ''}
    }
  }

  //================================================================= SAVE EMP/CUST
  saveOne = (type, data) => {
    const id = `${data.lastname} ${data.firstname}`
    const updatedFields = {
      obs: data.obs,
      portable: data.portable,
      comp: data.comp,
    }
    if (type === 'emp') {
      updatedFields.transport = data.transport
    }
    return this.person(type, id)
      .update(updatedFields)
      .then(() => true)
      .catch(() => false)
  }

  importOne = (type, data) => {
    const typeName = type === 'emp' ? 'intervenant' : 'bénéficiaire'
    const id = `${data.lastname} ${data.firstname}`
    return this.person(type, id)
      .get()
      .then(doc => {
        if (doc.exists) {
          const target = doc.data()
          // check to not overwrite data
          if (target.portable) data.portable = target.portable
          if (target.obs) data.obs = target.obs
          if (target.comp) data.comp = target.comp
          if (type === 'emp') {
            if (target.transport !== TRANSPORT.INCONNU) data.transport = target.transport
          }
          // Update if change on: address - email - phone
          if (
            data.phone !== target.phone ||
            data.email !== target.email ||
            data.address !== target.address
          ) {
            return this.person(type, id)
              .set(data)
              .then(() => `Modif. ${typeName}: ${id}`)
          } else {
            //return new Promise(resolve => resolve(`-- OK -- ${typeName}: ${id}`))
            return new Promise(resolve => resolve(''))
          }
        } else {
          return this.person(type, id)
            .set(data)
            .then(() => `Création ${typeName}: ${id}`)
        }
      })
  }

  // Planning API
  //
  planDay = id => this.firestore.doc(`p/${id}`)
  planList = () => this.firestore.collection('p')

  planListPerson = (table, id) => this.planDay(id).collection(table)
  planListPersonHour = (table, id, uid) =>
    this.planDay(id).collection(table).doc(uid).collection('hours')

  planListHour = id => this.planDay(id).collection('hours')

  planListHourForPlanningMap = (id, start, end) =>
    this.planDay(id)
      .collection('hours')
      .where('keytime', '>=', start)
      .where('keytime', '<=', end)

  planListHourFiltered = (id, start) =>
    this.planDay(id).collection('hours').where('keytime', '>=', start)

  planListHourEmp = (id, start) =>
    this.planDay(id).collection('hours').doc(start).collection('emp')

  NewImportLinePlanning = async data => {
    const id = data.keyday
    const cust = data.cust
    const emp = data.emp
    const start = data.keytime

    const current = `${data.keyday} ${data.keytime}`

    const DEBUG = false

    let doc
    let collection
    let createDay = false
    let createCust = false
    let createEmp = false
    let createHours = false
    let createCustHours = true
    let createEmpHours = true
    let createHoursEmp = true

    let written = 0
    let message = ''

    const pKey = `p/${id}`
    const custKey = `${pKey}/cust/${cust}`
    const empKey = `${pKey}/emp/${emp}`
    const custHoursKey = `${custKey}/hours/${start}`
    const empHoursKey = `${empKey}/hours/${start}`
    const hoursKey = `${pKey}/hours/${start}`
    const hoursEmpKey = `${hoursKey}/emp/${emp}`

    doc = await this.firestore.doc(custHoursKey).get()
    if (doc.exists) {
      createCustHours = false
      doc = await this.firestore.doc(empHoursKey).get()
      if (doc.exists) {
        createEmpHours = false
        doc = await this.firestore.doc(hoursEmpKey).get()
        if (doc.exists) {
          createHoursEmp = false
          return new Promise(resolve => resolve({written, message}))
        }
      }
    }

    collection = await this.firestore.collection(`${hoursKey}/emp/`).get()
    createHours = collection.size === 0

    if (createHours) {
      collection = await this.firestore.collection(`${empKey}/hours/`).get()
      createEmp = collection.size === 0

      if (createEmp) {
        createCust = true
        collection = await this.firestore.collection(`${pKey}/cust`).get()
        createDay = collection.size === 0
      }
    }

    // Collection P/DAY
    if (createDay) {
      if (DEBUG) console.log(`${pKey}`)
      await this.firestore.doc(pKey).set({keyday: id})
      written++
    }

    // Collection P/DAY/CUST/HOURS
    if (createCust) {
      if (DEBUG) console.log(`${custKey}`)
      await this.firestore.doc(custKey).set({cust: `${cust}`})
      written++
    }

    // Collection P/DAY/EMP/HOURS
    if (createEmp) {
      if (DEBUG) console.log(`${empKey}`)
      await this.firestore.doc(empKey).set({emp: `${emp}`})
      written++
    }

    // Collection P/DAY/HOURS
    if (createHours) {
      if (DEBUG) console.log(`${hoursKey}`)
      await this.firestore.doc(hoursKey).set({keytime: `${start}`})
      written++
    }

    if (createCustHours) {
      if (DEBUG) console.log(`${custHoursKey}`)
      await this.firestore
        .doc(custKey)
        .collection('hours')
        .doc(`${start}`)
        .set(data, {merge: true})
      written++
    }

    if (createEmpHours) {
      if (DEBUG) console.log(`${empHoursKey}`)
      await this.firestore
        .doc(empKey)
        .collection('hours')
        .doc(`${start}`)
        .set(data, {merge: true})
      written++
    }

    if (createHoursEmp) {
      if (DEBUG) console.log(`${hoursEmpKey}`)
      await this.firestore
        .doc(hoursKey)
        .collection('emp')
        .doc(`${emp}`)
        .set(data, {merge: true})
      written++
    }

    message =
      written > 0
        ? `${written} write${written > 1 ? 's' : ''} : ${current} ${hoursEmpKey}`
        : ''
    if (DEBUG && message) console.log(message)
    return new Promise(resolve => resolve({written, message}))
  }

  // Temporary procedure
  //
  searchUpdate = async table => {
    let nbUpdate = 0
    const srcList = await this.personList(table).get()
    for (const doc of srcList.docs) {
      const data = doc.data()

      const id = `${data.lastname} ${data.firstname}`
      const newData = {
        ...data,
        updateSearch: true,
      }

      if (!newData.srchcity || !newData.srchname) {
        let needUpdate = false
        if (!newData.srchcity && data.city) {
          newData.srchcity = buildSearchField(data.city)
          needUpdate = true
        }
        if (!newData.srchname && data.lastname) {
          newData.srchname = buildSearchField(data.lastname)
          needUpdate = true
        }
        if (needUpdate) {
          nbUpdate++
          console.log(`${id} => updated with ${newData.srchcity}/${newData.srchname}`, newData)
          this.person(table, id).set(newData)
        } else {
          console.log(`${id} => no update needed`)
        }
      } else {
        //console.log(`${id} => no update needed`)
      }
    }

    return `${nbUpdate} ${table.toUpperCase()}`
  }
  backupData = async table => {
    let nb = 0
    const srcList = await this.personList(table).get()
    for (const doc of srcList.docs) {
      const data = doc.data()
      const id = `${data.lastname} ${data.firstname}`
      //console.log(JSON.stringify(data))
      this.person(`save_${table}`, id).set(data)
      nb++
    }
    return nb
  }
  updateEmpCompData = data => {
    const id = `${data.lastname} ${data.firstname}`
    this.person('emp', id)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.person('emp', id)
            .set(data, {merge: true})
            .then(() => console.log(id))
        }
      })
  }
}

export default FbInstance

//================================================================= SAVE PLANNING
/*
  importLinePlanning = async data => {
    const id = data.keyday
    const cust = data.cust
    const emp = data.emp
    const start = data.keytime

    const current = `${data.keyday} ${data.keytime}`

    const DEBUG = false

    let doc = null
    let written = 0
    let message = ''

    const pKey = `p/${id}`
    const custKey = `${pKey}/cust/${cust}`
    const empKey = `${pKey}/emp/${emp}`
    const custHoursKey = `${custKey}/hours/${start}`
    const empHoursKey = `${empKey}/hours/${start}`
    const hoursKey = `${pKey}/hours/${start}`
    const hoursEmpKey = `${hoursKey}/emp/${emp}`

    // Collection P/DAY
    doc = await this.firestore.doc(pKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${pKey}`)
      await this.firestore.doc(pKey).set({keyday: id})
      written++
    }

    // Collection P/DAY/CUST/HOURS
    doc = await this.firestore.doc(custKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${custKey}`)
      await this.firestore.doc(custKey).set({cust: `${cust}`})
      written++
    }
    doc = await this.firestore.doc(custHoursKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${custHoursKey}`)
      await this.firestore
        .doc(custKey)
        .collection('hours')
        .doc(`${start}`)
        .set(data, {merge: true})
      written++
    }

    // Collection P/DAY/EMP/HOURS
    doc = await this.firestore.doc(empKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${empKey}`)
      await this.firestore.doc(empKey).set({emp: `${emp}`})
      written++
    }
    doc = await this.firestore.doc(empHoursKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${empHoursKey}`)
      await this.firestore
        .doc(empKey)
        .collection('hours')
        .doc(`${start}`)
        .set(data, {merge: true})
      written++
    }

    // Collection P/DAY/HOURS
    doc = await this.firestore.doc(hoursKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${hoursKey}`)
      await this.firestore.doc(hoursKey).set({keytime: `${start}`})
      written++
    }
    doc = await this.firestore.doc(hoursEmpKey).get()
    if (!doc.exists) {
      if (DEBUG) console.log(`${hoursEmpKey}`)
      await this.firestore
        .doc(hoursKey)
        .collection('emp')
        .doc(`${emp}`)
        .set(data, {merge: true})
      written++
    }

    message =
      written > 0
        ? `${written} write${written > 1 ? 's' : ''} : ${current} ${hoursEmpKey}`
        : ''
    if (DEBUG && message) console.log(message)
    return new Promise(resolve => resolve({written, message}))
  }
*/
