import React, {Suspense, useState, useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import './App.scss'
import './responsive.scss'
import 'react-datepicker/dist/react-datepicker.css'

import {AUTH_ROUTES} from './routes'
import {withFirebase} from './components/db'
import {Loading} from './components/common/complib'
import Layout from './components/Layout/Main'

import {registerLocale, setDefaultLocale} from 'react-datepicker'
import fr from 'date-fns/locale/fr'
registerLocale('fr', fr)
setDefaultLocale('fr')

// Pages
const SignUp = React.lazy(() => import('./components/auth/signup'))
const SignIn = React.lazy(() => import('./components/auth/signin'))
const SignOut = React.lazy(() => import('./components/auth/signout'))

const loading = () => <Loading />

const App = props => {
  const [firebaseInitialized, setFirebaseInitialized] = useState(false)
  //console.log('App props =>', props)

  useEffect(() => {
    props.db.isInitialized().then(() => {
      //console.log('App Firebase initialized =>', val)
      setFirebaseInitialized(true)
    })
  }, [props.db])

  return firebaseInitialized === true ? (
    <Router>
      {/* <Router basename={process.env.PUBLIC_URL}> */}
      <Suspense fallback={loading()}>
        <Switch>
          <Route
            exact
            path={AUTH_ROUTES.SIGN_IN}
            name="Login"
            render={props => <SignIn {...props} />}
          />
          <Route
            exact
            path={AUTH_ROUTES.SIGN_UP}
            name="Register"
            render={props => <SignUp {...props} />}
          />
          <Route
            exact
            path={AUTH_ROUTES.SIGN_OUT}
            name="Logout"
            render={props => <SignOut {...props} />}
          />
          <Route path="/" name="Accueil" render={props => <Layout {...props} />} />
        </Switch>
      </Suspense>
    </Router>
  ) : (
    <Loading />
  )
}

export default withFirebase(App)

/* 
const App = () => (
  <FbContext.Provider value={new FbInstance()}>
    <Router>
      <Suspense fallback={loading()}>
        <Switch>
          .
          .
        </Switch>
      </Suspense>
    </Router>
  </FbContext.Provider>
)
export default App
*/
